/**
 * 
 * 
 * 
 * @todo:
 * When i call:
 * let nodeBodyField = useParseInlineImages(node.body ? node.body.value : null, intl)
 * 
 * It complains about calling hook in wrong place refactor so we can call it!!!!
 * Temporaily i dosabled images conversion form Drupal!!!
 * 
 * 
 * Update all harmonograms in drupal availability field to 12!!!
 * @see: http://soft4net.local:8000/pl/soft4net?action=harmonogram-field_availability-set-12
 * 
 * 
 * 
 */

import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Img from 'gatsby-image'
import SEO from "./../../components/seo"

// s4n
// import "./../../assets/sass/templates/node/_blog.scss"
import FragmentHarmonogramDetails from '../../model/fragments/nodeHarmonogram/harmonogram-details'
import { injectIntl, Link } from "gatsby-plugin-intl"
import loadable from '@loadable/component'
import Preloader from './../../components/s4n/Preloader'
// import { useParseInlineImages } from '../../hooks/drupal/parseInlineImages'
import UseDrupalBlockContentBasic from '../../hooks/drupal/blockContentBasic'
import { Accordion, Card } from 'react-bootstrap'
import Harmonogram from './../../model/Harmonogram'
import GeneralUtils from './../../utils/common'
  // apollo
  // import { Query } from 'react-apollo';
  import gql from 'graphql-tag';
  import { client } from './../../apollo/client'



import kebabCase from "lodash.kebabcase"
import FormHeuresisHarmonogramZgloszenie from './../../components/forms/webform/heuresis_harmonogram_zgloszenie'



class ClassComponent extends React.Component {
  // constructor(props) {
  //     super(props)
  //     this.searchFulltextPhrase = `Aplikacje`;
  // }

  state = {
      isLoading: true,
      results: null,
      blCanReserve: false,
  }

  async getBookingData(nodeId) {
    this.setState({ isLoading: true })

    // @see: https://graphql-search-api.readthedocs.io/en/latest/
    const APOLLO_QUERY = gql`
    {
      nodeById(id: "${nodeId}") {
        ... on NodeHarmonogram {
          fieldReservationsSubmissions {
            targetId
          }
          fieldAvailability
        }
      }
    }  
    `

    let result = null;
    try {
      result = await client.query({query: APOLLO_QUERY});
    } catch (error) {
        // console.log(error)
    }

    // if (result.loading) {
    //     return <p>Loading...</p>
    // }

    this.setState({
      isLoading: false,
      results: result,
    })
  }

  componentDidMount() {
    const { data } = this.props;
    const nodeId = data.node.drupal_internal__nid;
    this.getBookingData(nodeId);
  }

  render() {

    // @todo: refactor gathering data since this should not be executed on every render cycle!!!
    const { data, intl } = this.props;
    const node = data.node;
    const t = intl.formatMessage;
  
    // const FormHeuresisHarmonogramZgloszenie = loadable(() => import(`./../../components/forms/webform/heuresis_harmonogram_zgloszenie`), {fallback: <Preloader />})
    const FormHeuresisHarmonogramZapytanie = loadable(() => import(`./../../components/forms/webform/heuresis_harmonogram_zapytanie`), {fallback: <Preloader />})

    // prepare/extract data
    // let img = node.relationships && node.relationships.field_image ? node.relationships.field_image.localFile.childImageSharp.fluid : null
    //let nodeBodyField = node.body ? node.body.value : null;
  
    // console.log(this.props)
    // console.log(node.body)
  
    // You can use the react-html-parser in case you don't want to use dangerouslySetInnerHTML attribute
    // let nodeBodyField = useParseInlineImages(node.body ? node.body.value : null, intl)
    let nodeBodyField = node.body ? node.body.value : null

    const oHarmonogramModel = new Harmonogram(node, intl);

    // @todo: replace with => oHarmonogramModel.canReserve()
    let blCanReserve = false;
    if (this.state.results) {
      const nodeBookingStatus = this.state.results.data.nodeById;
      if (nodeBookingStatus) {
        blCanReserve = nodeBookingStatus.fieldReservationsSubmissions.length < nodeBookingStatus.fieldAvailability;
      }
    }

    return (
      <Layout node={node}>
  
          <SEO 
            title={node.title} 
            keywords={`${node.title}, harmonogram`} 
            nodeId={node.drupal_internal__nid} 
            field_metatag={node.field_metatag}
          />
  
          <section id="node-harmonogram" className="node-harmonogram">
            <div className="container">
  
              <h1>{ node.title }</h1>
  
              {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, width: `100%` }}>
                {img && <Img fluid={ img } />}
              </div> */}
  
              <p>&nbsp;</p>
  
              <h2>{ t({ id: "soft4net_node_harmonogram_details" }) }</h2>
              <div className="wp-block-soft4net-container-block bg-blue my-4 py-0">
                  <div className="wp-block-soft4net-container-block container py-3">
  
                      <div className="section">
                          <h3>{ t({ id: "soft4net_node_harmonogram_coach" }) }</h3>
                          { node.field_coach_text 
                            && <p className="mb-0">{ node.field_coach_text }</p> }
                          {node.relationships.field_coach && node.relationships.field_coach.map((item, index) => {
                              return (
                                  <p>
                                    <Link to={`/${kebabCase(item.title)}`}>
                                      {item.title}
                                    </Link>
                                  </p>
                              )
                          })}
                      </div>
  
                      <div className="section">
                          <h3>{ t({ id: "soft4net_node_harmonogram_place" }) }</h3>
                          { node.relationships.field_place
                            && <p>{ node.relationships.field_place.name }</p> }
                      </div>
  
                      <div className="section">
                          <h3>{ t({ id: "soft4net_node_harmonogram_date" }) }</h3>
                          <p>{ oHarmonogramModel.getDate() }</p>
                          {node.relationships.field_lectures.length ? // it's an [] if there's no lectures
                            <React.Fragment>
                                <p className="mb-2">
                                  <h3>{ GeneralUtils.capitalizeFirstLetter(t({ id: "soft4net_node_harmonogram_lectures" })) }</h3>
                                </p>
                                <Accordion defaultActiveKey={`soft4net_node_harmonogram_lectures-0`}>
                                    {node.relationships.field_lectures.map((item, index) => {
                                      return (
                                          <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={`soft4net_node_harmonogram_lectures-${index}`}>
                                              <strong>
                                                  {item.field_title}, {GeneralUtils.dateFormat(item.field_date_from, null, intl.locale)} - {GeneralUtils.dateFormat(item.field_date_to, null, intl.locale)}
                                              </strong>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={`soft4net_node_harmonogram_lectures-${index}`}>
                                              <Card.Body>
                                                <div dangerouslySetInnerHTML={{ __html: item.field_description ? item.field_description.value : '' }}></div>
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                      )
                                    })}
                                </Accordion>
                            </React.Fragment> : null}
  
                      </div>
  
                  </div>
              </div>
  
              <div className="wp-block-soft4net-container-block bg-grey my-4 py-0">
                <div className="wp-block-soft4net-container-block container py-3">
                  {/* {nodeBodyField ? <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div> : <UseDrupalBlockContentBasic blockId="58" />} */}

                  {/* Harmonogram shared common description */}
                  <UseDrupalBlockContentBasic blockId="58" />

                  <div dangerouslySetInnerHTML={{ __html: nodeBodyField }}></div>
                </div>
              </div>
  
              <Accordion defaultActiveKey="soft4net_node_harmonogram_question" className="mb-2">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="soft4net_node_harmonogram_question">
                    <strong>{ t({ id: "soft4net_node_harmonogram_question" }) }</strong>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="soft4net_node_harmonogram_question">
                    <Card.Body>
                      <FormHeuresisHarmonogramZapytanie harmonogramNode={node} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>                
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="soft4net_node_harmonogram_reservation">
                    <strong>{ t({ id: "soft4net_node_harmonogram_reservation" }) }</strong>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="soft4net_node_harmonogram_reservation">
                    <Card.Body>
                      {blCanReserve ?
                        <FormHeuresisHarmonogramZgloszenie harmonogramNode={node} /> 
                        :
                        <div className="alert alert-danger mb-0" role="alert">
                          { t({ id: "soft4net_node_harmonogram_reservation_not_available" }) }
                        </div>
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
  
            </div>
          </section>
  
      </Layout>
    )
  }
}

export default injectIntl(ClassComponent)

// this query is Promie, we export it to let Gatsby fill variables from context from gatsby-node.js (id )
// and pass back to this file as props to below component
export const query = graphql`
    query(
      $id: String!,
      $drupal_id: String!,
      $langcode: String!
    ) {
    node: nodeHarmonogram(
      id: {
        eq: $id
      },
      drupal_id: {
        eq: $drupal_id
      },
      langcode: {
        eq: $langcode
      }
    ) {
        ...FragmentHarmonogramDetails
    }
  }
`
